.plug{
    background-color: $color-2;
    .plug__wrapper{
        display: flex;
        padding: 70px 0;
        align-items: center;
        width: 100%;
        h2{
            color: $color-1;
            font-size: 2.3rem;
        }
        .all-project-btn{
            a{
                color: $color-3;
                font-size: 1.5rem;
                &:hover{
                    text-decoration: underline !important;
                    color: $color-3;
                }
            }
        }
    }
}

@media screen and (max-width: 991px){
    .plug .plug__wrapper .all-project-btn{
        display: none;
    }
}
