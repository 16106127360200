$color-1: #FFFFFF;
$color-2: #333333;
$color-3: #FFD157;
$color-4: #DCF2F7;
$color-5:  #F8F8F8;
$color-6: #828999;
$color-7: #4B5C6E;

body, html {
  min-width: 320px;
  margin: 0px;
  padding: 0px;
  background-color: $color-1;
  font-size: 16px;
  height: 100%;
}
body{
  min-height: 100%;
}
.hide{
  display: none !important;
}
a{
  color: black;
  text-decoration: none !important;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1rem;
  &:hover{
    color: inherit;
  }
}
.main-paddings{
  padding: 100px 0;
}
.title{
  margin-bottom: 50px;
}
h1{
  font-size: 4.5625rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: $color-1;
}
h2{
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 2.8125rem;
  color: $color-2;
}
h3{
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1.875rem;
}
h4{
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  color: $color-2;
}
h5{
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
}
h6{
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1.125rem;
  color: $color-2;
}
p{
  font-family: 'Roboto', sans-serif;
  
  $color-2: #333333;
}
.tabs-wrapper{
  .nav-item:before{
    display: none;
  }
  .nav-tabs{
    border-bottom: none;
    margin-bottom: 150px;
    .nav-item{
      width: 25%;
    }
  }
  .nav-link{
    border: none;
    background-color: $color-3;
    border-radius: 0;
    color: $color-2;
    padding: 15px 0px;
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    &.active{
      background-color: $color-2;
      color: $color-1;
    }
  }
  .tab-pane{
    display: flex;
    flex-wrap: wrap;
  }
  .drop-down{
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }
}
.breadcrumbs{
  margin-bottom: 35px;
  a{
    text-decoration: underline !important;
    color: $color-2;
  }
  span{
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1rem;
    color: $color-6;
  }
}
ul{
  margin: 0;
  padding: 0;
  li{
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: $color-2;
    position: relative;
    margin-bottom: 5px;
    &:before{
      content: '-'; 
      margin-right: 3px;     
    }
  }
}
li{
  list-style-type: none;
}
.unscroll{
  overflow: hidden;
}
.slick-slide{
  outline: none;
  a{
    outline: none;
  }
}
.slick-dots{
  display: flex;
  justify-content: center;
  align-items: center;
  li{
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: #C4C4C4;
      position: relative;
      overflow: hidden;
      margin-right: 20px;
      &.slick-active{
          width: 15px;
          height: 15px;
          background-color: $color-2;
      }
      &:last-child{
          margin-right: 0;
      }
      button{
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          padding: 0;
          width: 100%;
          height: 100%;
          font-size: 0;
          
      }
      &:before{
          display: none;
      }
  }
}


@media screen and (max-width: 1680px){
  body,
  html{
    font-size: 15px;
  }
}
@media screen and (max-width: 1600px){
  body,
  html{
    font-size: 14px;
  }
}
@media screen and (max-width: 1440px){
  body,
  html{
    font-size: 13px;
  }
}
@media screen and (max-width: 1366px){
  body,
  html{
    font-size: 12px;
  }
}
@media screen and (max-width: 991px){
  body,
  html{
    font-size: 11px;
  }
  .main-paddings{
    padding: 30px 0;
  }
  .title{
    margin-bottom: 25px;
  }
  h1{
    font-size: 28px;
  }
  h2{
    font-size: 24px;
  }
  h4,h5{
    font-size: 18px;
  }
  p{
    font-size: 14px;
  }
  ul li{
    font-size: 14px;
  }
  a{
    font-size: 16px;
  }
  .breadcrumbs{
    display: none;
  }
  .tabs-wrapper{
    .nav-tabs{
      margin-bottom: 40px;
      .nav-item{
        width: 100%;
      }
    }
    .nav-link{
      text-transform: none;
      text-align: left;
      padding: 15px;
      &.active{
        display: none;
      }
    }
  }
}