.square-with-title{
    margin-bottom: 90px;
    .img-wrapper{
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        margin-bottom: 25px;
    }
    h6{
        text-align: center;
    }
}


@media screen and (max-width: 991px){
    .square-with-title{
        margin-bottom: 25px;
        .img-wrapper{
            max-width: 500px;
            margin: 0 auto 15px auto;
        }
    }
}