.aims{
    &__item{
        margin-bottom: 35px;
    }
    .text{
        line-height: 200%;
    }
    .title--aims{
        margin-bottom: 35px;
    }
}