.only-img{
    width: 100%;
    img{
        width: 100%;
        height: auto;
    }
    &__signature{
        font-size: 12px;
        color: $color-2;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        text-align: center;
        padding: 12px 22px;
        border: 1px solid $color-5;
    }
}