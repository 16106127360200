.error-page__main-field{
    background-color: $color-7;
    height: 100%;
    width: 100%;
    .wrapper{
        width: 90%;
        margin: 0 auto;
    }
    ._404{
        font-size: 11.875rem;
        font-weight: 300;
        font-family: 'Roboto', sans-serif;
        color: $color-1;
        line-height: 1;
        margin-right: 5.5rem;
        color: rgba(255, 255, 255, 0.6);

    }
    .text{
        h2{
            margin-bottom: 3.125rem;
            color: $color-1;
        }
        p{
            font-size: 1.5rem;
            color: rgba(255, 255, 255, 0.6);
            margin-bottom: 2rem;
        }
        .btn{
            padding: 0;
            a{
                color: $color-3;
                text-decoration: underline;
                font-size: 1.5rem;
            }
        }
    }
}


@media screen and (max-width: 991px){
    .error-page__main-field {
        .container,
        .row{
            height: 100%;
        }
        .wrapper{
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            min-height: 290px;
            overflow: auto;
        }
        ._404{
            order: 2;
            text-align: center;
            margin-right: 0;
            img{
                max-width: 80%;
            }
        }
        .text{
            order: 1;
            h2{
                font-size: 27px;
            }
            p{
                margin-bottom: 14px;
            }
            .btn{
                a{
                    font-size: 18px;
                }
            }
        }
    }
}