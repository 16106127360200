.our-works{
    padding-bottom: 0;
    .our-works__wrapper{
        width: 100%;
        display: flex;
        flex-wrap: wrap; 
    }
    .slick-dots{
        margin: 0 auto;
    }
}


@media screen and (max-width: 991px){
    .our-works{
        padding-bottom: 30px;
    }
}