
#mymap{
    height: 500px;
    width: 100%;
}

@media screen and (max-width: 991px){
    #mymap{
        height: 270px;
    }  
}