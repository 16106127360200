.carusel{
    background-color: $color-5;;
}
.carusel-wrapper{
    width: 100%;
    .carusel-wrapper__item{
        padding: 2rem;
        background-color: $color-1;
        margin: 0 15px;
        outline: none;
        .content{
            padding-bottom: 25px;
            margin-bottom: 25px;
            border-bottom: 1px dashed $color-2;
        }
        p{
            font-family: 'Open Sans', sans-serif;
            margin-bottom: 0;
        }
        .author{
            display: flex;
            align-items: center;
        }
        .authot-img{
            width: 4.375rem;
            border-radius: 50%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            margin-right: 1.5rem;
        }
        .author__text{
            display: flex;
            flex-direction: column;
            p{
                font-style: italic;
            }
        }
    }
    .slick-dots{
        margin-top: 75px;
    }
}

@media screen and (max-width: 991px){
    .carusel-wrapper .slick-dots{
        margin-top: 25px;
    }
}