.slider-popup{
    padding-top: 75px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    height: 100%;
    width: 100%;
    background-color: #F7F7F7;
    overflow: auto;
    transition: all .3s ease-in-out;
    opacity: 1;
    visibility: visible;
    &.popup-hide{
        opacity: 0;
        visibility: hidden;
    }
    .close-btn{
        position: absolute;
        top: 20px;
        right: 30px;
    }
    .big-slider,
    .small-slider{
        width: 100%;
    }

    .big-slider__wrapper{
        max-width: 500px;
        width: 100%;
        margin: 0 auto 75px auto;
        position: relative;
        .square-with-title{
            margin-bottom: 0;
        }
        .arrows{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -150px;
            display: flex;
            justify-content: space-between;
            width: calc(100% + 300px);
            .prev{
                transform: rotate(180deg);
            }
        }
        .big-slider__counter{
            position: absolute;
            bottom: 0px;
            right: 0;
            display: flex;
            margin-bottom: .5rem;
            font-family: "Roboto", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 150%;
            letter-spacing: 0.02em;
            color: #4B5C6E;
            text-shadow: 0px 0px 4px rgba(1, 6, 35, 0.05);
            p{
                margin-bottom: 0rem;
                font-family: "Roboto", sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 150%;
                letter-spacing: 0.02em;
                color: #4B5C6E;
                text-shadow: 0px 0px 4px rgba(1, 6, 35, 0.05);
            }
        }
        .slick-dots{
            height: 0;
            visibility: hidden;
            opacity: 0;
        }
    }

    .small-slider{
        .square-with-title{
            margin: 0 15px;
            &__title{
                display: none;
            }
        }
    }
}

// @media screen and (max-width: 1320px){
//     .slider-popup .big-slider__wrapper .arrows{
//         width: 100%;
//         left: 0;
//     }
// }