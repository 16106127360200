.four-square-wrapper{
    background-color: $color-5;
    .slider-wrapper{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        position: relative;
    }
    .slick-dots{
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
    }
}