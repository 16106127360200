/*  Raleway Bold */
@font-face {
  font-family: 'Raleway Bold';
  src: url('../fonts/Raleway-Bold.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Raleway-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Raleway-Bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Raleway-Bold.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/Raleway-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Raleway-Bold.svg#Raleway-Bold') format('svg'); /* Legacy iOS */
}

/* Raleway Regular */
@font-face {
  font-family: 'Raleway Regular';
  src: url('../fonts/Raleway-Regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Raleway-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Raleway-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Raleway-Regular.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/Raleway-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Raleway-Regular.svg#Raleway-Regular') format('svg'); /* Legacy iOS */
}

/*  Raleway Light */
@font-face {
  font-family: 'Raleway Light';
  src: url('../fonts/Raleway-Light.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Raleway-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Raleway-Light.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Raleway-Light.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/Raleway-Light.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Raleway-Light.svg#Raleway-Light') format('svg'); /* Legacy iOS */
}