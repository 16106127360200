.three-block-not-on-full-page{
    &__wrapper{
        display: flex;
        flex-wrap: wrap;
    }
    &__item{
        width: 30%;
        margin-right: 15px;
    }
}

@media screen and (max-width: 991px){
    .three-block-not-on-full-page{
        &__wrapper{
            flex-direction: column;
        }
        &__item{
            width: 100%;
            max-width: 500px;
            margin: 0 auto;
        }
    }
}