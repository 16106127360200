.numbers{
    .numbers__wrapper{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .number-item{
        width: 20%;
        padding: 0 10px;
        margin-bottom: 20px;
        .number{
            font-size: 3.6875rem;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            text-align: center;
            line-height: 1;
            margin-bottom: 10px;
            color: $color-2;
        }
        p{
            text-align: center;
        }
    }
}

@media screen and (max-width: 991px){
    .numbers .number-item{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 50%;
        background-color: $color-3;
        margin: 0 10px 50px 10px;
        width: unset;
        padding: 0;
        .number{
            font-size: 59px;
        }
    }
    .numbers .slick-dots{
        margin: 0 auto;
    }
}