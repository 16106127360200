.have-question{
    background-color: $color-3;
    display: flex;
    justify-content: center;
    h3{
        font-size: 2.1875rem;
        font-family: 'Open Sans', sans-serif;
        color: $color-2;
    }
    p{
        font-family: 'Open Sans', sans-serif;
        font-style: italic;
        color: $color-2;
    }
    input{
        font-size: 1rem;
        padding: 7px 15px;
        font-family: 'Open Sans', sans-serif;
    }
    input[type='tel']{
        color: $color-2;
        margin-right: 7px;
    }
    input[type='submit']{
        background-color: $color-2;
        color: $color-1;
        border: 1px solid $color-2;
    }
}

.let-question-more{
    h3{
        text-transform: uppercase;
    }
    p{
        font-weight: 600;
    }
    input{
        min-width: 233px;
        min-height: 35px;
        border-radius: 3px;
        border: none;
    } 
    input[type='submit']{
        min-width: 200px;
    }
}

@media screen and (max-width: 991px){
  .have-question{
      padding-left: 15px;
      padding-right: 15px;
    h3{
        font-weight: bold;
        margin-bottom: 13px;
    }
    p{
        font-style: normal;  
        font-family: 'Roboto', sans-serif;
        line-height: 194.53%;
    }
    form{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    input{
        margin-bottom: 20px;
        width: 100%;
        max-width: 320px;
        font-size: 15px;
    }
    input[type='tel']{
        margin-right: 0;
    }
    input[type='submit']{
        border-radius: 3px;
    }
  }  
  .let-question-more{
    input{
        min-width: unset;
    } 
  }
}