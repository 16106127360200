.content-page{
    position: relative;
}
.content-page__sidebar{
    margin-top: 65px;
    padding: 0;
    .wrapper{
        border-left: 1px dashed $color-6;
        padding: 0 15px 180px 15px;
    }
    h5{
        margin-bottom: 35px;
    }
    form{
        display: flex;
        flex-direction: column;
        margin-bottom: 90px;
        width: 89%;
        input,
        textarea{
            color: $color-2;
            background-color: #EFEFEF;
            padding: 6px 12px;
            outline: none;
            outline: none;
            border: none;
            margin-bottom: 20px;
            border-radius: 3px;
            font-family: 'Open Sans', sans-serif;
            font-size: 1rem;
            &::placeholder{
                color: $color-2;
            }
        }
        input[type="submit"]{
            background-color: $color-1; 
            border: 1px solid #EFEFEF;
            font-weight: 600;
        }
        textarea{
            resize: none;
            height: 90px;
        }
    }
    .popular-question{
        li{
          margin-bottom: 25px;  
          &:before{
              display: none;
          }
        }
    }
}


@media screen and (max-width: 991px){
    .content-page__sidebar{
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateY(100%);
        &.active{
            display: block;
        }
        .wrapper{
            border-left: none;
            display: flex;
            flex-direction: column;
            padding: 0;
            width: 100%;
        }
        h5{
            font-size: 26px;
            margin-bottom: 21px;
        }
        form{
            order: 2;
            margin-bottom: 0;
            background-color: $color-5;
            padding: 30px 15px 50px 15px;
            width: 100%;
            input,
            textarea{
                font-size: 15px;
                background-color: $color-1;
            }
            input[type='submit']{
                color: $color-1;
                background-color: $color-2;
                margin-bottom: 0;
            }
        }
        .popular-question{
            order: 1;
            margin-top: 30px;
            margin-bottom: 70px;
            padding: 0 15px;
            width: 720px;
            margin: 30px auto 50px auto;
            li{
                margin-bottom: 15px;
                a{
                    font-size: 14px;
                    font-weight: 400;
                }
            } 
        }
    }   
}

@media screen and (max-width: 767px){
    .content-page__sidebar .popular-question{
        width: 540px;
    }
}

@media screen and (max-width: 574px){
    .content-page__sidebar .popular-question{
        width: 100%;
    }
}