.intro-img{
    position: relative;
    img{
        width: 100%;
    }
    .text{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 3rem;
        background-color: rgba(255,255,255, .5);
    }
}

@media screen and (max-width: 991px){
    .content-page__main-field .intro-img{
        margin-top: 0 !important;
        width: calc(100% + 30px);
        transform: translateX(-15px);
    }
}