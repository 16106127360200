.small-intro{
    min-height: 450px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    padding: 50px 0;
    align-items: center;
    &__title{
        height: 100%;

    }
}


@media screen and (max-width: 991px){
    .small-intro{
        align-items: flex-end;  
    }
}