.open-popular-question-btn{
    padding: 25px 0;
    display: none;
    background-color: $color-5;
    box-shadow: 0px -5px 5px -4px rgba(0,0,0,0.15);;
    .wrapper{
        width: 100%;
    }
    a{
        display: block;
        margin: 0 auto;
        width: 100%;
        max-width: 500px;
        text-align: center;
        padding: 10px 0;
        background-color: $color-2;
        color: $color-1
    }
}

@media screen and (max-width: 991px){
    .open-popular-question-btn{
        display: block;
    }  
}