.content-page__main-field {
    margin-top: 65px;
    .content-page__wrapper{
        width: 90%;
    }
    .content-page__margins{
        margin: 40px 0;
    }
    .content-page__navigation{
        padding: 30px;
        background-color: rgba(255,209,87, 0.5);
        margin-bottom: 40px;
        li{
            text-decoration: underline;
            margin-bottom: 25px;
            &:last-child{
                margin-bottom: 0;
            }
            &:before{
                display: none;
            }
        }
    }
}


@media screen and (max-width: 991px){
    .content-page__main-field{
        margin-top: 20px;
        overflow: hidden;
        &--portfolio-object{
            margin-top: 0;
        }
        .content-page__wrapper{
            width: 100%;
        }
        .content-page__navigation{
            width: calc(100% + 30px);
        }
    }
}

@media screen and (max-width: 575px){
    .content-page__main-field .content-page__navigation{
        transform: translateX(-15px);
        padding: 30px 15px;
    }
}