.tabs{
    .drop-down{
        display: none;
        background-color: $color-2;
        justify-content: space-between;
        padding: 15px;
        p{
            margin-bottom: 0;
            color: $color-1;
            font-size: 16px;
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
        }
    }
}

@media screen and (max-width: 991px){
    .tabs{
        .drop-down{
            display: flex;
        }
        .nav-tabs{
            max-width: 500px;
            margin: 0 auto 40px auto;
        }
    }   
}