.text-with-img{
    display: flex;
    .text{
        margin-right: 30px;
    }
    .img-wrapper{
        img{
            width: 100%;
            height: auto;
        }
        &__signature{
            font-size: 12px;
            color: $color-2;
            font-family: 'Roboto', sans-serif;
            font-weight: 300;
            text-align: center;
            padding: 12px 22px;
            border: 1px solid $color-5;
        }
    }
}
.text-with-img--small-img{
    .text{
        width: calc(67.7% - 30px);
    }
    .img-wrapper{
        width: 32.3%;  
    }
}
.text-with-img--big-img{
    .text{
        width: 32.3%;   
    }
    .img-wrapper{
        width: calc(67.7% - 30px);
    }
}


@media screen and (max-width: 991px){
  .text-with-img{
      flex-direction: column;
      .text{
          order: 2;
          width: 100%;
      }
      .img-wrapper{
          order: 1;
          width: 100%;
          margin-bottom: 25px;
      }
  }  
}