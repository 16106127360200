.square-with-list{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    .img-wrapper{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 25px;
    }
}
