.contacts{
    footer{
        display: none;
    } 
    h5{
        margin-bottom: 30px;
    }
    li{
        &:before{
            display: none;
        }
    }
    .contacts-form__wrapper{
        a{
            color: $color-2;
        }
        display: flex;
        justify-content: space-between;
        form{
            display: flex;
            flex-wrap: wrap;
            h5,
            textarea{
                width: 100%;
    
            }
            textarea,
            input{
                margin-bottom: 20px;
                padding-left: 12px;
                background-color: #EFEFEF;
                color: $color-2;
                border: none;
            }
            textarea{
                height: 100px;
                resize: none;
            }
            input{
                width: 48.7%;
                margin-right: 2%;
                box-sizing: border-box;
                height: 35px;
            }
            input[type='text']{
                margin-right: 0;
            }
            input[type='submit']{
                height: 40px;
                padding-left: 0;
                background-color: $color-1;
                border: 2px solid #EFEFEF;
    
            }
        }
    }

}

@media screen and (max-width: 991px){
    .contacts-form{
        padding: 0;
    }
    .contacts{
        footer{
            display: flex;
        }
        .contacts-form__wrapper,
        .contacts-form__title{
            display: none;
        }
        .mobile-form form{
            background-color: #EFEFEF;
            input,
            textarea{
                border: none;
            }
        }
    }
}