.technology{
    .technology__wrapper{
        display: flex;
        align-items: flex-start;
        margin-bottom: 30px;
        .img-wrapper{
            width: 8.375rem;
            border-radius: 50%;
            background-color: #DCF2F7;
            margin-right: 1.875rem;
            img{
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 991px){
    .technology .technology__wrapper{
        max-width: 500px;
    }
}