.content-page__slider{
    margin: 40px 0;
    background-color: #F8F8F8;
    .title{
        margin-bottom: 25px;
    }
    .slider-and-arrow{
        width: 100%;
        position: relative;
        padding: 50px 0;
        .square-with-title{
            margin-bottom: 0;
        }
        .arrows{
            position: absolute;
            width: 120%;
            top: 50%;
            left: -10%;
            transform: translateY(-50%);
            display: flex;
            justify-content: space-between;

            .prev{
                a{
                    transform: rotate(180deg);
                }
            }
            a{
                display: block;
                width: 100px;
                img{
                    width: 100%;
                }
            }
        }   
    }
    .slider-wrapper{
        width: 100%; 
        z-index: 2;  
    }
}

@media screen and (max-width: 1400px){
    .content-page__slider .slider-and-arrow .arrows{
        display: none;
    }
}

@media screen and (max-width: 1200px){
    .content-page__slider .slider-and-arrow .arrows{
        display: flex;
        width: calc(100% + 50px);
        left: -25px;
        a{
            width: 50px;
        }
    } 
}

@media screen and (max-width: 991px){
    .content-page__slider .slider-and-arrow .arrows{
        display: none;
    }
}