.tabs-three-huge-square{
    li{
        &:before{
            display: none;
        }
    }
    .filter-wrapper__drop-menu{
        display: flex;
        align-items: center;
        p{
            margin-right: 20px;
            margin-bottom: 0;
        }
    }
    .drop-menu-btn{
        position: relative;
        min-width: 200px;
        a{
            width: 100%;
            padding: 7px 20px;
            border: 1px solid $color-6;
            display: inline-flex;
            border-radius: 20px;
            cursor: pointer;
        }
        img{
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .filter-wrapper__tabs{
        position: absolute;
        min-width: 100%;
        display: flex;
        flex-direction: column;
        border-bottom: none;
        background-color: white;
        z-index: 2;
        .filter-wrapper__item{
            
            position: relative;
            .active{
                display: none;
            }
            a{
                border: none;
                width: 100%;
            }
        }
    }
    .tabs-three-huge-square__styling{
        display: flex;
        flex-wrap: wrap;
        .tabs-three-huge-square__item-wrapper{
            width: 33.3333%;
            position: relative;
            &:hover{
                .btn-wrapper{
                    opacity: 1;
                    visibility: visible;
                }
                
            }
            .wrapper__img{
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
            .btn-wrapper{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                visibility: hidden;
                transition: all .3s ease-in-out;
                a{
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: relative;
                }
                .content{
                    display: flex;
                    flex-direction: column;
                    background-color: rgba(0, 0, 0, 0.7);
                    width: 100%;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                    h2,p{
                        color: $color-1;
                        text-align: center;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 991px){
    .tabs-three-huge-square{
        .filter-wrapper__drop-menu{
            p{
                display: none;
            }
            .drop-menu-btn{
                width: 100%;
                max-width: 500px;
                margin: 0 auto;
                a{
                    border-radius: 0;
                    color: $color-1;
                    background-color: $color-2;
                    border: none;
                }
                p{
                    display: block;
                    font-size: 16px;
                }
            }
        }
        .filter-wrapper__tabs{
            background-color: $color-3;
            .filter-wrapper__item{
                a{
                    background-color: transparent;
                    color: $color-2;
                }
            }
        }
        .tabs-three-huge-square__items{
            display: none;
        }
        .tabs-three-huge-square__styling{
            .tabs-three-huge-square__item-wrapper{
                width: 500px;
                display: block;
            }
        }
        .mobile-slider{
            position: relative;
            .counter{
                display: flex;
                right: 17px;

                font-family: 'Roboto', sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 150%;
                text-align: right;
                letter-spacing: 0.02em;
                p{
                    margin-bottom: 0;
                    font-family: 'Roboto', sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 150%;
                    text-align: right;
                    letter-spacing: 0.02em;
                }
            }
            .tabs-three-huge-square__item-wrapper{
                width: 500px;
                display: block;
                .btn-wrapper{
                    position: static;
                    padding: 50px 15px 0 15px;
                    opacity: 1;
                    visibility: visible;
                    .content{
                        background-color: transparent;
                        h2,
                        p{
                            color: $color-2;
                        }
                    }
                }
            }
            .slick-dots{
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

}