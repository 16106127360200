header{
    position: relative;
    padding: 21px 0;
    background-color: $color-2;
    width: 100%;
    position: fixed;
    background-color: transparent;
    z-index: 10;
    transition: all .5s ease-in-out;
    &.header--black{
        background-color: $color-2;
    }
    &.black{
        background-color: $color-2;
    }
    .burger{
        display: none;
        width: 18px;
        height: 13px;
        align-items: center;
        .burger__wrapper{
            position: relative;
        }
        &.active{
            span{
                &:nth-child(1){
                    top: 0px;
                    transform: rotate(405deg);
                }
                &:nth-child(2){
                    width: 0;
                }
                &:nth-child(3){
                    bottom: 0px;
                    transform: rotate(-405deg);
                }
            }    
        }
        span{
            display: block;
            width: 18px;
            height: 2px;
            background-color: $color-3;
            transition: all .5s ease-in-out;
            &:nth-child(1){
                position: absolute;
                top: -5px;
            }
            &:nth-child(3){
                position: absolute;
                bottom: -5px;
            }
        }
    }
    .mobile-logo{
        display: none;
        a{
            display: block;
        }
    }
    .mobile-call-icon{
        display: none;
        a{
            display: block;
        }
    }
    .mobile-list{
        display: none;
    }
}
.header-wrapper{
    display: flex;
    position: unset;
}
header nav{
    width: 100%;
    ul{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    a{
        color: $color-1;
        display: flex;
        align-items: center;
        &:hover{
            color: $color-1;
        }
    }
    li{
        position: static;
        &::before{
            display: none;
        }
    }
    img{
        margin-left: 10px;
    }
    .with-submenu{
        img{
            transition: all 0.3s ease-in-out;
        }
        &.active{
            .sub-menu-arrow-img{
                transform: rotate(180deg);
            }
            .sub-menu{
                opacity: 1;
                visibility: visible;
            }    
        }
        .sub-menu{
            position: absolute;
            left: 0;
            background-color: $color-3;
            width: 100%;
            margin-top: 27px;
            opacity: 0;
            visibility: hidden;
            transition: all .5s ease-in-out;
            .sub-sub__menu{
                img{
                    display: none;
                }
            }
        }

    }
    .sub-menu__wrapper{
        padding: 40px 0;
        display: flex;
        justify-content: space-around;
        ul{
            
            flex-direction: column;
            justify-content: unset;
            align-items: flex-start;
        }
        li{
            margin-bottom: 1rem;
            position: static;
        }
        .submenu-title{
            a{
                color: $color-2;
                font-weight: 500;
                margin-bottom: 1rem;
            }
        }
        a{
            color: $color-2;
            font-weight: 400;
        } 
    }
    .telephones{
        position: relative;
        img{
            transition: all 0.3s ease-in-out;
        }
        &.active{
            .telephones__submenu{
                opacity: 1;
                visibility: visible;
            }
            img{
                transform: rotate(180deg);
            }
        }
        .telephones__submenu{
            position: absolute;
            width: calc(100% + 30px);
            padding: 15px 15px 0 15px;
            left: -25px;
            opacity: 0;
            visibility: hidden;
            transition: all .5s ease-in-out;
            background-color: $color-2;
            li{
                margin: 0 auto 1rem auto;
            }
        }
    }
    .mail{
        .mail__mobile{
            display: none;
        }
    }
}
header .logo{
    max-width: 100px;
    a{
        height: 100%;
        display: block;
    }
    img{
        width: 100%;
        margin-left: 0;
    }
}

@media screen and (max-width: 991px){
    header{
        padding: 10px 0;
        .burger{
            display: flex;
        }
        .logo{
            max-width: 720px;
            margin: 0 auto 15px auto;
            a{
                max-width: 100px;
            }
        }

        nav{
            padding: 15px 0; 
            position: fixed;
            top: 44px;
            left: 0;
            background-color: $color-2;
            opacity: 0;
            visibility: hidden;
            transition: all .5s ease-in-out;
            &.active{
                opacity: 1;
                visibility: visible;
                padding-bottom: 30px;
                overflow: auto;
            }
            ul{
                flex-direction: column;
                align-items: flex-start;
                padding: 0 15px;
                margin: 0 auto;
            }
            li{
                width: 100%;
                margin: 0 auto 15px auto;
                padding: 0 15px;
                color: $color-1;
                max-width: 720px;

            }
            .telephones{
                display: none;
            }
            .mail{
                .mail__desctop{
                    display: none;
                }
                .mail__mobile{
                    display: block;
                    a{
                        padding: 5px 15px;
                        color: $color-1;
                        background-color: #5F5F5F;
                        display: inline-block;
                        border-radius: 5px;
                    }
                }
            }
            .mobile-list{
                display: block;
                ul{
                    padding: 0;
                }
                li{
                    margin-bottom: 7px;
                    color: $color-1;
                    font-weight: 300;
                    a{
                        color: $color-1;
                    font-weight: 300;

                    }
                }
            }
            .with-submenu{   
                max-width: 100%;
                padding: 0;
                a{
                    display: block;
                    max-width: 720px;
                    margin: 0 auto;
                    padding: 0 15px;
                }
                &.active{
                    margin-bottom: 0;
                    .sub-menu{
                        height: auto;
                        padding: 15px;
                        margin: 15px 0;
                    }
                }
                .sub-menu{
                    position: static;
                    opacity: 1;
                    visibility: visible;
                    padding: 0 15px;
                    margin: 0;
                    transform: translateX(-15px);
                    width: calc(100% + 30px);
                    height: 0;
                    transition: none;
                    overflow: hidden;
                }
            } 
            .sub-menu__wrapper{
                padding: 0;
                flex-direction: column;
                a{
                    display: flex;
                }
                .sub-sub__menu{
                    &.active{
                        ul{
                            display: block;
                        }
                    }
                    ul{
                        display: none;
                    }
                }
                .submenu-title{
                    a{
                        img{
                            display: block !important;
                        }
                    }
                    &.active{
                        a{
                            color: $color-2;
                            img{
                                transform: rotate(0deg);
                            }
                        }
                    }
                    a{
                        color: $color-2 !important;
                        font-weight: 500;
                        font-size: 18px;
                        margin-bottom: 1rem;
                        img{
                            transform: rotate(180deg);
                        }
                    }
                }
                ul{
                    max-width: 100%;
                    margin: unset;
                    padding-left: 10px;
                }
            }
        }
        .header-wrapper{
            position: relative;
            justify-content: space-between;
            align-items: center;
        }
        .mobile-logo,
        .mobile-call-icon{
            display: block;
        }
    }
    .header--position-absolute{
        background-color: $color-2;
    }
}
@media screen and (max-width: 767px){
    header nav ul li{
        max-width: 540px;
    }
    header .logo{
        max-width: 540px;
    }
    header nav .with-submenu{   
        max-width: 100%;
        a{
            max-width: 540px;  
        }
    }
} 
@media screen and (max-width: 575px){
    header nav ul{
        max-width: 100%;
        padding: 0;
    }

} 