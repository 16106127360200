.three-huge-square-btns{
    .three-huge-square-btns__slider{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
}
.counter{
    position: absolute;
}
.three-huge-square-btns__wrapper{
    width: 33.33333%;
    position: relative;
    &:hover{
        .btn-wrapper{
            opacity: 1;
            visibility: visible;
        }
    }
    .btn-wrapper{
        opacity: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        transition: all .3s ease-in-out;
        opacity: 0;
        visibility: hidden;
        
        a{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 1;
            h2,p{
                color: $color-1;
                text-align: center;
            }
        }
        .mobile-btn{
            display: none;
            a{
                color: $color-3;
            }   
        }
    }
}
.three-huge-square-btns__item{
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;  
}

@media screen and (max-width: 991px){
    .three-huge-square-btns{
        .three-huge-square-btns__slider{
            position: relative;
            .slick-dots{
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                li.slick-active{
                    background-color: $color-1;
                }
            }
        }
        &__item{
            max-width: 400px;
            margin: 0 auto;
        }
        &__wrapper{
            background-color: $color-6;
            .btn-wrapper{
                position: static;
                opacity: 1;
                visibility: visible;
                background-color: $color-2;
                padding-bottom: 50px;
                
                .mobile-btn{
                    display: block;
                }
            }
        }
    }
}
