.articles{
    position: relative;

}
.articles__main-field{
    margin-top: 65px;
    margin-bottom: 80px;
    padding: 0 30px 0 15px;
    .articles__wrapper{
        width: 90%;
    }
    .filter-wrapper{
        &__drop-menu{
            display: flex;
            align-items: center;
        }
        p{
            margin-right: 20px;
            margin-bottom: 0;
        }
        li{
            &:before{
                display: none;
            }
        }
        .drop-menu-btn{
            position: relative;
            min-width: 200px;
            a{
                width: 100%;
                padding: 7px 20px;
                border: 1px solid $color-6;
                display: inline-flex;
                border-radius: 20px;
                cursor: pointer;
            }
            img{
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
            }

        }
        .filter-wrapper__tabs{
            position: absolute;
            min-width: 100%;
            display: flex;
            flex-direction: column;
            border-bottom: none;
            background-color: white;
            z-index: 2;
            .filter-wrapper__item{
                
                position: relative;
                .active{
                    display: none;
                }
                a{
                    border: none;
                    width: 100%;
                }
            }
        }
    }
    .articles-items{
        margin-top: 32px;  
        .article-item{
            width: 100%;
            display: flex;
            background-color: #F8F8F8;
            height: 160px;
            margin-bottom: 25px;
            font-weight: 400;
            &__img{
                width: 160px;
                height: 160px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }
            &__text{
                padding: 30px;
                width: calc(100% - 160px);
                p{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3; /* number of lines to show */
                    margin-bottom: 0;
                }                
            }
        }
    }
    .load-more{
        display: flex;
        justify-content: center;
        margin-top: 50px;
        a{
            color: $color-6;
            &:hover{
                text-decoration: underline !important;
            }
        }
    }
}



@media screen and (max-width: 991px){
    .content-page__main-field{
        margin-top: 20px;
        .articles__wrapper{
            width: 100%;
        }

    }
    .articles__main-field{
        margin: 20px auto 35px auto;
        padding: 0 15px 0 15px;
        .filter-wrapper{
            &__drop-menu{
                p{
                    display: none;
                }
                .drop-menu-btn{
                    width: 100%;
                    max-width: 500px;
                    margin: 0 auto;
                    a{
                        border-radius: 0;
                        color: $color-1;
                        background-color: $color-2;
                        border: none;
                    }
                    p{
                        display: block;
                        font-size: 16px;
                    }
                }
            }
            &__tabs{
                background-color: $color-3 !important;
                .filter-wrapper__item{
                    a{
                        background-color: transparent;
                        color: $color-2;
                    }
                }
            }
        }
        .articles-items{
            .article-item{
                flex-direction: column;
                height: unset;
                width: 100%;
                max-width: 500px;
                margin: 0 auto 45px auto;
                background-color: transparent;
                &__img{
                    width: 100%;
                    height: 200px;
                    margin-bottom: 15px;
                }
                &__text{
                    width: 100%;
                    padding: 0;
                    h5{
                        margin-bottom: 15px;
                    }
                    p{
                        -webkit-line-clamp: 4;
                    }
                }
            }
        }
    }
}