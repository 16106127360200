.slider-with-circle{
    background-color: $color-5;
    .slider-with-circle__wrapper{
        position: relative;
        z-index: 1;
    }
    &__item{
        margin: 0 10px;
        position: relative;
        &:hover{
            .text{
                opacity: 1;
                visibility: visible;
            }
        }
        .avatar{
            position: relative;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 50%;
            z-index: 1;
            height: 100%;
        }
        .text{
            position: absolute;
            top: 0;
            z-index: 2;
            background-color: $color-2;
            width: 100%;
            height: 100%;
            padding: 20px;
            opacity: 0;
            visibility: hidden;
            transition: all .3s ease-in-out;
            &.active{

            }
            h3,p{
                color: $color-1;
            }
        }
    }
    &__arrows{
        position: absolute;
        width: 120%;
        top: 50%;
        left: -10%;
        transform: translateY(-50%);
        display: flex;
        justify-content: space-between;
        z-index: 0;
        .prev{
            a{
                transform: rotate(180deg);
            }
        }
        a{
            display: block;
            width: 100px;
            img{
                width: 100%;
            }
        }
    }
}


@media screen and (max-width: 1400px){
    .slider-with-circle__arrows{
        display: none;
    }
}
@media screen and (max-width: 1200px){
    .slider-with-circle__arrows{
        display: flex;
        width: calc(100% + 50px);
        left: -25px;
        a{
            width: 50px;
        }
    } 
}

@media screen and (max-width: 991px){
    .slider-with-circle__item{
        .avatar{
            border-radius: unset;
        }
        .text{
            opacity: 1;
            visibility: visible;
            position: static;
            height: auto;
            background-color: transparent;
            h3,p{
                text-align: center;
                color: $color-2;

            }
        }
    }
    .slider-with-circle .slick-dots{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    .slider-with-circle__slider{
        padding: 0;
        position: relative;
    }
    .slider-with-circle__arrows{
        display: none;
    }

}

@media screen and (max-width: 575px){
    .slider-with-circle__item{
        margin: 0;
    }
}