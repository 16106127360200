footer{
    background-color: #111111;
    padding: 50px 0 200px 0;
    p{
        color: $color-1;
    }
    .logo{
        img{
            margin-bottom: 25px;
        }
    }
    ul{
        li{
            color: $color-1;
            margin-bottom: 20px;
            font-family: 'Open Sans', sans-serif;
            &:before{
                display: none;
            }
        }
        .facebook{
            font-family: 'Open Sans', sans-serif;
            padding: 5px 10px;
            background-color: $color-2;
            border-radius: 5px;
            display: inline-block;
        }
        a{
            font-family: 'Open Sans', sans-serif;
            display: flex;
            align-items: center;
            color: $color-1;
            img{
                margin-right: 10px;
            }
        }
    }
    .to-top-btn{
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        a{
            display: inline-block;
            padding: 10px 12px 12px 12px;
            background-color: $color-2;
            border-radius: 5px;
        }
    }
    .mobile-form{
        display: none;
    }
    .facebook-mobile{
        display: none;
    }
    .mobile-form{
        width: 100%;
        margin: 50px 0;
        background-color: #EFEFEF;
        form{
            padding: 25px 15px 75px 15px;
            margin: 0 auto;
            h2{
                margin-bottom: 25px;
            }
            input,
            textarea{
                width: 100%;
                outline: none;
                margin-bottom: 11px;
                padding: 0 13px;
                font-family: 'Open Sans', sans-serif;
                font-size: 15px;
                color: $color-2;
                &::placeholder{
                    color: $color-2;
                }
            }
            input{
                height: 45px;
            }
            input[type='submit']{
                background-color: $color-2;
                color: $color-1;
                border: none;
            }
            textarea{
                resize: none;
                height: 75px;
                padding: 13px;
            }
        }
    }
}

@media screen and (min-width: 768px){
    footer{
        .container {
            max-width: 100%;
        }
    }
}


@media screen and (max-width: 991px){
    footer{
        padding: 15px 0 100px 0;
        .address{
            max-width: 720px;
            margin: 0 auto;
            order: 1;
            .facebook{
                display: none;
            }
            ul{
                display: flex;
                flex-direction: column;
            }
            li{
                order: 2;
            }
            .address__wrapper{
                order: 1;
                display: flex;
                justify-content: center;
            }
        }
        .phones{
            order: 2;
            max-width: 720px;
            margin: 0 auto;
            .phones-title{
                display: none;
            }
        }
        .logo{
            order: 4;
            max-width: 720px;
            margin: 0 auto;
            img{
                margin-bottom: 0;
            }
            .logo__wrapper{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 25px;
            }

        }
        .to-top-btn{
            display: none;
        }
        .facebook-mobile{
            display: inline-block;
            max-width: 720px;
            margin: 0 auto;
            padding: 5px 12px 7px 12px;
            background-color: $color-2;
            border-radius: 5px;
            a{
                color: $color-1;
            }
        }
        .mobile-form{
            display: block;
            order: 3;
            padding: 0;
            form{
                max-width: 720px;
            }
        }
    }
}

@media screen and (max-width: 768px){
    footer{
        .container {
            max-width: 100%; // 540
        }
        .address{
            max-width: 540px;  
        }
        .phones{
            max-width: 540px;  
        }
        .logo{
            max-width: 540px;  
        }
        .mobile-form{
            form{
                max-width: 540px;
            }
        }
    }
}

@media screen and (max-width: 575px){
    footer .mobile-form{
        padding: 0;
    }
    footer{
        .address,
        .phones{
            padding: 0 25px;
        }
    }
}