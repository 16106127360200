.intro{
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right center;
    display: flex;
    align-items: center;
    position: relative;
    .intro__wrapper{
        padding: 50px 0;
    }
    .intro__title{
        width: 50%;
        margin-bottom: 80px;
    }
    .intro-mobile-logo{
        width: 130px;
        margin-bottom: 30px;
        display: none;
        a{
            display: block;
            width: 100%;
            height: 100%;
        }
        img{
            width: 100%;
        }
    }
    .ask-question{
        a{
            position: relative;
            display: block;
            width: 390px;
            padding: 15px 0;
            border: 2px solid $color-1;
            color: $color-1;
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            font-size: 20px;
            border-radius: 5px;
            text-align: center;
            z-index: 1;
            overflow: hidden;
            transition: all .5s ease-in-out;
            &:after{
                content: "";
                width: 0%;
                height: 100%;
                position: absolute;
                left: 0%;
                top: 0;
                background-color: $color-3;
                border-top-right-radius: 25px;
                border-bottom-right-radius: 25px;
                z-index: -1;
                transition: all .5s ease-in-out;
            }
            &:hover{
                color: $color-2;
                border-color: $color-3;
                &:after{
                    width: calc(100% + 25px);
                }
            }
        }
        img{
            position: absolute;
            top: 50%;
            right: 23px;
            transform: translateY(-50%);
        }
    }
    .scroll-icon{
        width: 38px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        
        img{
            width: 100%;
        }
    }
}

@media screen and (max-width: 991px){
    .intro{
        align-items: flex-end;
        h1{
            font-size: 29px;
            line-height: 182.45%;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            font-family: 'Roboto', sans-serif;
            font-weight: bold;
        }
        .intro__wrapper{
            padding: 0 15px;
            .ask-question{
                display: none;
            }
        }
        .intro-mobile-logo{
            display: block;
        }
        .intro__title{
            width: 100%;
        }
        .scroll-icon{
            display: none;
        }
    }
}