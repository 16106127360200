.also-about-us{
    background-color: $color-5;
    .our-works__wrapper{
        margin-bottom: 90px;
    }
    .square-with-title{
        border: 2px solid #E9EBF2;
        margin-bottom: 0;
        height: 100%;
        .img-wrapper{
            margin-bottom: 0;
        }
        &__title{
            padding: 25px;
            h6{
                text-align: left;
            }
        }
    }
}

@media screen and (max-width: 991px){
    .our-works__item{
        padding-bottom: 0;
    }
    .also-about-us{
        .our-works__wrapper{
            margin-bottom: 69px;
        }
        .slick-list{
            margin-bottom: 20px;
        }
        .slick-track{
            height: 100%;
        }
    }
}