.technology--4{
    .technology--4__item{
        margin-bottom: 30px;
        .img-wrapper{
            width: 7.375rem;
            border-radius: 50%;
            background-color: $color-4;
            margin-right: 1.875rem;
            margin-bottom: 30px;
            img{
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 991px){
    .technology--4 .technology--4__item{
        display: flex;
        align-items: flex-start;
        .img-wrapper{
            min-width: 73px;
        }
    }
}